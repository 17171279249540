<template>
  <div class="status-bar">
    <span>Total Count: <strong>{{ count }}</strong></span>
  </div>
</template>

<script>

export default {
  data() {
    return {
      count: 0
    }
  },
  mounted() {
    this.$emitter.on('status_bar_update', (data) => {
      this.count = data.count
    })
  }
}
</script>

<style lang="scss" scoped>
.status-bar {
  display: flex;
  min-height: 44px;
  align-items: center;
}
</style>
